// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme mixins
@import "2-mixins/background";
@import "2-mixins/badge";
@import "2-mixins/button";
@import "2-mixins/modal";
@import "2-mixins/switch";
@import "2-mixins/tabs";
@import "2-mixins/theme";
@import "2-mixins/wizard";

// Theme components
@import "3-components/accordion";
@import "3-components/alert";
@import "3-components/avatar";
@import "3-components/badge";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/chat";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/forms";
@import "3-components/hamburger";
@import "3-components/illustrations";
@import "3-components/landing";
@import "3-components/main";
@import "3-components/modal";
@import "3-components/navbar";
@import "3-components/progress";
@import "3-components/reboot";
@import "3-components/root";
@import "3-components/settings";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stats";
@import "3-components/tables";
@import "3-components/tabs";
@import "3-components/timeline";
@import "3-components/type";
@import "3-components/wizard";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/background";
@import "4-utilities/borders";
@import "4-utilities/cursors";
@import "4-utilities/font";
@import "4-utilities/overflow";
@import "4-utilities/position";
@import "4-utilities/z-index";

// 3rd party plugins
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
//@import "~apexcharts/src/assets/apexcharts";
//@import "~bootstrap-daterangepicker/daterangepicker";
//@import "~datatables.net-bs4/css/dataTables.bootstrap4";
//@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4";
//@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";
//@import "~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4";
//@import "~dragula/dist/dragula";
@import "~fullcalendar/main";
//@import "~jsvectormap/dist/css/jsvectormap";
@import "~notyf/notyf.min";
//@import "~quill/dist/quill.bubble";
//@import "~quill/dist/quill.snow";
//@import "~select2/src/scss/core";
@import "~simplebar/dist/simplebar";
//@import "~smartwizard/dist/css/smart_wizard_all";
//@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4";

// 3rd party plugin styles
//@import "5-vendor/datatables-select";
//@import "5-vendor/daterangepicker";
//@import "5-vendor/datetimepicker";
@import "5-vendor/fullcalendar";
//@import "5-vendor/highlight";
//@import "5-vendor/jsvectormap";
//@import "5-vendor/quill";
@import "5-vendor/select2";
@import "5-vendor/simplebar";
//@import "5-vendor/smartwizard";

// Custom
[v-cloak] {
  display: none;
}
.bg-orange {
  background-color: #f98214;
}
.bg-light-orange {
  background-color: #fef8f3;
}
.bg-green {
  background-color: #68ae00;
}
.bg-light-green {
  background-color: #eff6e5;
}
.bg-blue {
  background-color: #337ab7;
}
.bg-light-blue {
  background-color: #eaf1f7;
}
.bg-gray {
  background-color: #999999;
}
.transparent-panel {
  opacity: 0.5;
}
.col-xxl-4 {
  width: 45%
};
@media screen and (max-width: 520px) {.col-xxl-4{width:100%}}
